html,
body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background-color: #1a2236;
  overflow-x: hidden;
}

.container {
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100% !important;
  height: 100%;
}

.nav-container {
  margin: auto;
}

a {
  text-decoration: none;
}

#root, .main-wrapper {
    overflow: visible !important;
}

#zmmtg-root {
    display: none;
}

.av-preview-container {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    border-radius: 0 !important;
}

.media-preview-control {
    position: fixed !important;
    bottom: 16px !important;
    left: calc(50vw - 176px) !important;
    height: 60px !important;
}

.meeting-title {
    position: absolute !important;
    display: none !important;
}

#join-btn {
    position: fixed !important;
    bottom: 16px !important;
    left: calc(50vw + 16px) !important;
    background-color: black !important;
    border-color: black !important;
    height: 60px !important;
    width: 175px !important;
}

.main-content {
    width: 100% !important;
    padding: 0 !important;
}

#notificationManager {
    z-index: 9999 !important;
    position: absolute;
    top: 92px !important;
}